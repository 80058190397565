import Map from 'ol/Map.js';
import TileLayer from 'ol/layer/Tile.js';
import TileWMS from 'ol/source/TileWMS.js';
import View from 'ol/View.js';
import {Fill, Stroke, Style, Text} from 'ol/style.js';
import VectorTileLayer from 'ol/layer/VectorTile.js';
import VectorTileSource from 'ol/source/VectorTile.js';
import MVT from 'ol/format/MVT.js';
import 'ol/ol.css';
import Select from 'ol/interaction/Select.js';
import {altKeyOnly, click, pointerMove} from 'ol/events/condition.js';
import {BaseLayerOptions, GroupLayerOptions} from 'ol-layerswitcher';
import LayerGroup from 'ol/layer/Group.js';
import LayerSwitcher from 'ol-ext/control/LayerSwitcher.js'
import 'ol-ext/control/LayerSwitcher.css'
import GeoTIFF from 'ol/source/GeoTIFF.js';


/* STYLES */
const style = new Style({
    stroke: new Stroke({
        color: '#3bb48c', width: 2,
    }), fill: new Fill({
        color: '#00000000'
    }),
});

const max = 50;
function normalize(value) {
    return ['/', value, max];
}

const red = normalize(['band', 1]);
const green = normalize(['band', 2]);
const blue = normalize(['band', 3]);
const nir = normalize(['band', 4]);
const ndvi = {
    color: [
        'interpolate',
        ['linear'],
        ['/', ['-', nir, red], ['+', nir, red]],
        // color ramp for NDVI values, ranging from -1 to 1
        -0.2,
        [191, 191, 191],
        -0.1,
        [219, 219, 219],
        0,
        [255, 255, 224],
        0.025,
        [255, 250, 204],
        0.05,
        [237, 232, 181],
        0.075,
        [222, 217, 156],
        0.1,
        [204, 199, 130],
        0.125,
        [189, 184, 107],
        0.15,
        [176, 194, 97],
        0.175,
        [163, 204, 89],
        0.2,
        [145, 191, 82],
        0.25,
        [128, 179, 71],
        0.3,
        [112, 163, 64],
        0.35,
        [97, 150, 54],
        0.4,
        [79, 138, 46],
        0.45,
        [64, 125, 36],
        0.5,
        [48, 110, 28],
        0.55,
        [33, 97, 18],
        0.6,
        [15, 84, 10],
        0.65,
        [0, 69, 0],
    ],
};

/* SOURCE */
let tree_url = 'https://geo.bs-vr.ru/geoserver/gwc/service/tms/1.0.0/dtwin%3A'
let layer_name = 'crowns_out_18_11_2023_14_11_14_iou05_c02'

function get_vector_layers() {
    let layers_name = []
    let color_pallete = [
        "#ffd97d",
        "#aad941",
        "#E2D1F9",
        "#60d394",
        "#d4a373",
        "#2ec4b6",
        "#f7aef8",
        "#fb5607",
        "#5e60ce",
        "#F4DFC8",
        "#ffb703",
        "#d9ed92",
        "#caf0f8"
    ]
    let xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://geo.bs-vr.ru/geoserver/gwc/service/tms/1.0.0/');
    xhr.send();

    xhr.onload = function () {
        if (xhr.status != 200) {
            console.log(`Error fetch geoserver layers ${xhr.status}: ${xhr.statusText}`);
        } else {
            let layer_urls = xhr.responseXML.querySelectorAll('[srs="EPSG:3857"]');
            let layers_arr = [];
            layer_urls.forEach(function (item, i, arr) {
                var valueToPush = { }; // or "var valueToPush = new Object();" which is the same
                valueToPush["url"] = item.getAttribute("href");
                valueToPush["title"] = item.getAttribute("title");
                if(valueToPush["title"].search('crowns') !== -1) layers_arr.push(valueToPush);
            });
            layers_arr = layers_arr.sort(function(a,b) {return a.title.localeCompare(b.title) })
            layers_arr.forEach(function (item, i, arr) {
                let layer_url = item.url;
                let layer_name = item.title;
                //map.getLayers().getArray().find(layer => console.log(layer.get('title')));
                //console.log(map.getLayers().getArray().find(layer => layer.get('title') == layer_name));
                if(i > color_pallete.length-1){
                    var color_n = i - Math.floor(i/color_pallete.length)*color_pallete.length
                }else{
                    var color_n = i
                }
                let layer = new VectorTileLayer({
                    title: layer_name,
                    source: new VectorTileSource({
                        maxZoom: 19,
                        format: new MVT({}),
                        url: layer_url + layer_name + '@WebMercatorQuadx2@pbf/{z}/{x}/{-y}.pbf',
                    }),
                    style: new Style({
                        stroke: new Stroke({
                            color: color_pallete[color_n], width: 2,
                        }), fill: new Fill({
                            color: '#00000000'
                        }),
                    }),
                    visible: i===layers_arr.length-1,
                });
                //layer.setZIndex(i);
                crownsLayers.getLayers().array_.push(layer)
                ///map.addLayer(layer);
                layers_name.push(layer_name);
            });
        }
        /*  SWITCHER
        var layerSwitcher = new LayerSwitcher({
            startActive: true, activationMode: 'click',
            opacitySlider: true
        });*/
        map.addControl(
            new LayerSwitcher({
                startActive: true,
                //show_progress:true,
                extent: true,
                collapsed: false,
                //trash: true,
            })
        )
        //map.addControl(layerSwitcher);
    };
    return layers_name;
}

const wms_url = 'https://map.bs-vr.ru/service';

/* LAYERS */

var baseLayers = new LayerGroup({
    title: 'Base Layers',
    openInLayerSwitcher: true,
    layers: [
        new TileLayer({
        title: 'OSM',
            type: 'base',
            visible: false,
            source: new TileWMS({
            url: wms_url,
                params: {'LAYERS': 'osm_bs_layer'},
        }),
    }),
        new TileLayer({
        title: 'yMaps Satellite',
            type: 'base',
            visible: true,
            source: new TileWMS({
            url: wms_url,
                params: {'LAYERS': 'yandex_sat_layer'},
        }),
    })]
});

var gchmLayers = new LayerGroup({
    title: 'Global Canopy Height Model',
    openInLayerSwitcher: true,
    layers: [
        new TileLayer({
            title: 'gchm_heighmap(20230920T123757)_std', visible: false, source: new TileWMS({
                url: 'https://geo.bs-vr.ru/geoserver/ows/', params: {'LAYERS': 'dtwin:gchm_heighmap(20230920T123757)_std'},
            }),
        }),
        /*new TileLayer({
            style: ndvi,
            source: new GeoTIFF({
                normalize: false,
                sources: [
                    {
                        url: 'https://tree.bs-vr.ru/gchm_heighmap(20230920T123757)_prd.tif',
                    },
                ],
            }),
        })*/
        new TileLayer({
            title: 'gchm_heighmap(20230920T123757)_prd', visible: true, source: new TileWMS({
                url: 'https://geo.bs-vr.ru/geoserver/ows/',
                params: {'LAYERS': 'dtwin:gchm_heighmap(20230920T123757)_prd'},
            })
        })
]
});
var crownsLayers = new LayerGroup({
    title: 'Crowns',
    openInLayerSwitcher: true,
    layers: []
});

/*  MAP  */
let map = new Map({
    layers: [baseLayers, gchmLayers, crownsLayers], target: 'map', view: new View({
        center: [4899577.0861, 7613012.7645], zoom: 18,
    }),
});

let lname = get_vector_layers()

/* Interactive */
/* display badge */
const badge = document.getElementById('badge');
let currentFeature;
const displayFeatureInfo = function (pixel, target) {
    const feature = target.closest('.ol-control') ? undefined : map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
    });

    if (feature) {
        badge.style.left = pixel[0] + 'px';
        badge.style.top = pixel[1] + 'px';
        if (feature !== currentFeature) {
            badge.style.visibility = 'visible';
            badge.innerText = parseFloat(feature.get('Confidence_score')).toFixed(3);
        }
    } else {
        badge.style.visibility = 'hidden';
    }
    currentFeature = feature;
};


map.on('pointermove', function (e, layer) {
    displayFeatureInfo(e.pixel, e.originalEvent.target);
});


/*  hide  */
map.getTargetElement().addEventListener('pointerleave', function () {
    currentFeature = undefined;
    badge.style.visibility = 'hidden';
});